import { gql } from 'graphql-request';

import type { Pagination } from '@/hooks/use-pagination';

import { postFragment } from './post-fragment';
import { mapSingle } from './single';
import type { News } from './types';

export const createPaginatedNewsListQuery = () => gql`
  ${postFragment}
  query getPaginatedNewsList($category: String, $limit: Int!, $page: Int) {
    Posts(
      sort: "-publishedDate"
      where: { category: { equals: $category } }
      limit: $limit
      page: $page
    ) {
      totalDocs
      page
      docs {
        ...postFragment
      }
    }
  }
`;

export const createNewsListQuery = () => gql`
  ${postFragment}
  query getNewsList($category: String, $limit: Int!, $exclude: [String]) {
    Posts(
      sort: "-publishedDate"
      limit: $limit
      where: { category: { equals: $category }, slug: { not_in: $exclude } }
    ) {
      docs {
        ...postFragment
      }
    }
  }
`;

export const mapToNewsList = (data: any): News[] => {
  return data?.Posts?.docs.map(mapSingle);
};

export const mapToPaginatedNewsList = (data: any): Pagination<News> => {
  const docs = data.Posts.docs.map(mapSingle);

  return {
    total: data.Posts.totalDocs,
    page: data.Posts.page,
    docs,
  };
};
