import { gql } from 'graphql-request';

export const richTextFragment = gql`
  fragment richTextFragment on RichText {
    id
    content
  }
`;

export interface RichText {
  __typename: 'RichText';
  id: string;
  content: any;
}
