import type { CookieList } from './cookie-list';
import { cookieListFragment } from './cookie-list';
import type { FaqsWithCategory } from './faqs-with-category';
import { faqsWithCategoryFragment } from './faqs-with-category';
import type { Html } from './html';
import { htmlFragment } from './html';
import type { Image } from './image';
import { imageFragment } from './image';
import type { InstagramBlock } from './instagram-embed';
import { instagramFragment } from './instagram-embed';
import type { RichText } from './rich-text';
import { richTextFragment } from './rich-text';
import type { TwitterBlock } from './twitter-embed';
import { twitterFragment } from './twitter-embed';
import type { Video } from './video';
import { videoFragment } from './video';

export const pageContentBlocks = `
  ${richTextFragment}
  ${faqsWithCategoryFragment}
  ${videoFragment}
  ${imageFragment}
  ${htmlFragment}
  ${cookieListFragment}
`;

export type PageContentBlock =
  | RichText
  | FaqsWithCategory
  | Video
  | Image
  | Html
  | CookieList;

export const postContentBlocks = `
  ${richTextFragment}
  ${imageFragment}
  ${videoFragment}
  ${htmlFragment}
  ${instagramFragment}
  ${twitterFragment}
  `;

export type PostContentBlock =
  | RichText
  | Image
  | Video
  | Html
  | InstagramBlock
  | TwitterBlock;
