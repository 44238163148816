import { gql } from 'graphql-request';

export const twitterFragment = gql`
  fragment twitterFragment on TwitterBlock {
    id
    url
    width
  }
`;

export interface TwitterBlock {
  __typename: 'TwitterBlock';
  id: string;
  url: string;
  width?: string;
}
