import type IConfig from 'config/Config';

import createGraphqlClient from './graphql-client-creator';

async function makeSiteGraphqlRequest<Type = any>(
  config: IConfig,
  query: string,
  variables: any,
  formatFn?: (data: any) => Type
): Promise<Type> {
  let url = config.cmsUrl;
  // if nextjs client side, use the api route
  if (typeof window !== 'undefined') {
    url = config.webCmsUrl || config.cmsUrl;
  }

  const client = createGraphqlClient(url, 'POST');

  try {
    const data: Type = await client.request(query, variables);

    if (formatFn) {
      return formatFn(data);
    }

    return data;
  } catch (error) {
    console.error('Error making request', error, url, variables);
    throw error;
  }
}

export default makeSiteGraphqlRequest;
