import { gql } from 'graphql-request';

export const instagramFragment = gql`
  fragment instagramFragment on InstagramBlock {
    id
    url
    width
  }
`;

export interface InstagramBlock {
  __typename: 'InstagramBlock';
  id: string;
  url: string;
  width?: string;
}
