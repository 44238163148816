import clsx from 'clsx';
import React from 'react';

export interface IVimeoEmbed {
  id: string;
  hideByline?: boolean;
  hideTitle?: boolean;
  start?: string;
  color?: string;
  className?: string;
  customUrl?: string;
}

export const VimeoEmbed = ({ id, customUrl, className }: IVimeoEmbed) => {
  const srcPrefix = 'https://player.vimeo.com/video/';
  const defaultParams = {
    autoplay: true,
    byline: false,
    dnt: true,
    muted: true,
    playsinline: true,
    title: false,
    loop: true,
  };
  const params = Object.entries(defaultParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return (
    <iframe
      className={clsx('h-full w-full', className)}
      title="Embedded Vimeo video"
      src={customUrl || `${srcPrefix}${id}&${params}`}
      width="100%"
      height="100%"
      frameBorder="0"
      allowFullScreen
    />
  );
};
