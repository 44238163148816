import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

interface IRichText {
  className?: string;
  children: ReactNode;
}

const RichText: FC<IRichText> = ({ children, className }) => {
  if (!children) return null;
  const typography = [
    'prose-h2:md:text-3xl',
    'prose-h3:md:text-2xl',
    'prose-h4:md:text-xl',
    'prose-p:md:text-base',
  ];

  return (
    <div
      className={clsx(
        `prose-p:text-md prose max-w-none`,
        ...typography,
        className
      )}
    >
      {children}
    </div>
  );
};

export default RichText;
