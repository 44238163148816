import { gql } from 'graphql-request';

export const videoFragment = gql`
  fragment videoFragment on VideoBlock {
    id
    videoType
    videoId
    caption
  }
`;

export interface Video {
  __typename: 'VideoBlock';
  id: string;
  videoType: string;
  videoId: string;
  caption: string;
}
