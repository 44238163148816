import React from 'react';

import type { RichText as RichTextBlockType } from '@/blocks/rich-text';
import RichText from '@/components/Primitive/RichText/RichText';
import Serialize from '@/utils/serializer';

interface IRichTextBlock {
  block: RichTextBlockType;
  className?: string;
}

export const RichTextBlock: React.FC<IRichTextBlock> = ({
  block,
  className,
}) => {
  return <RichText className={className}>{Serialize(block.content)}</RichText>;
};
