import { gql } from 'graphql-request';

export const imageFragment = gql`
  fragment imageFragment on ImageBlock {
    id
    image {
      id
      url
    }
    caption
  }
`;

export interface Image {
  __typename: 'ImageBlock';
  id: string;
  image: {
    id: string;
    url: string;
  };
  caption: string;
}
