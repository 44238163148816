import { gql } from 'graphql-request';

import { postContentBlocks } from '@/blocks';

export const postFragment = gql`
  ${postContentBlocks}
  fragment postFragment on Post {
    id
    title
    sell
    publishedDate
    hero {
      caption
      heroImage {
        sizes {
          free {
            url
          }
        }
      }
    }
    content {
      __typename
      ...richTextFragment
      ...imageFragment
      ...videoFragment
      ...htmlFragment
      ...instagramFragment
      ...twitterFragment
    }
    slug
    seo {
      title
      description
      image {
        url
      }
    }
    thumbnail {
      sizes {
        thumbnail {
          url
        }
        square {
          url
        }
        free {
          url
        }
      }
    }
    category {
      id
      name
    }
  }
`;
