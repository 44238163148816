import { useEffect } from 'react';

function useKeyPress(targetKey: string, callback: () => void) {
  // State for keeping track of whether key is pressed
  // If pressed key is our target key then set to true
  function downHandler({ key }: any) {
    if (key === targetKey) {
      callback();
    }
  }
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
}

export default useKeyPress;
