import { gql } from 'graphql-request';

import { postFragment } from './post-fragment';
import type { News } from './types';

export const createNewsPostPagePreviewQuery = () => gql`
  ${postFragment}
  query getNewsPostPreview($id: String!) {
    Post(id: $id, draft: true) {
      ...postFragment
    }
  }
`;

export const createNewsPostPageQuery = () => gql`
  ${postFragment}
  query getNewsPost($slug: String!) {
    Posts(where: { slug: { equals: $slug } }, limit: 1) {
      docs {
        ...postFragment
      }
    }
  }
`;

export const mapSingle = (data: any): News => {
  return {
    id: data?.id,
    sell: data?.sell || '',
    title: data?.title,
    publishedDate: data?.publishedDate,
    content: data?.content,
    slug: data?.slug,
    thumbnail: data?.thumbnail?.sizes?.square.url || '',
    thumbnailLarge: data?.thumbnail?.sizes?.free?.url || '',
    heroImage: {
      url: data?.hero?.heroImage?.sizes?.free?.url || '',
      caption: data?.hero?.caption || '',
    },
    href: `/news/${data?.slug}`,
    ...(data?.category?.id && {
      category: {
        id: data?.category?.id,
        name: data?.category?.name,
      },
    }),
    seo: data.seo,
  };
};

export const mapToNewsPostPreviewPage = (data: any): News | null => {
  return mapSingle(data.Post);
};

export const mapToNewsPostPage = (data: any): News | null => {
  const [content] = data?.Posts?.docs || [];
  if (!content) return null;

  return mapSingle(content);
};
