import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

import SmartLink from '@/components/Primitive/SmartLink';

interface IPostCardAlt {
  title: string;
  thumbnail: string;
  href: string;
  className?: string;
  category?: string;
}

const PostCardAlt = ({
  title,
  thumbnail,
  href,
  category,
  className,
}: IPostCardAlt) => {
  return (
    <SmartLink
      to={href}
      className={clsx(
        'hover-scale group relative block rounded-b-sm bg-blackAlt/40',
        className
      )}
    >
      <div className="w-full overflow-hidden rounded-t-sm">
        <Image
          src={thumbnail}
          alt={title}
          width={220}
          height={130}
          className="w-full rounded-t-sm object-cover"
        />
      </div>
      <div className="relative flex w-full flex-col space-y-2 p-3">
        {category && <div className="text-base text-cream">{category}</div>}
        <h3 className="text-md line-clamp-3 font-medium text-offWhite">
          {title}
        </h3>
      </div>
    </SmartLink>
  );
};

export default PostCardAlt;
