import { gql } from 'graphql-request';

export const cookieListFragment = gql`
  fragment cookieListFragment on CookieList {
    id
  }
`;

export interface CookieList {
  __typename: 'CookieList';
  id: string;
}
