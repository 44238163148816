import 'keen-slider/keen-slider.min.css';

import clsx from 'clsx';
import type { KeenSliderOptions } from 'keen-slider/react';
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

import PostCardAlt from '@/components/Core/PostCard/PostCardAlt';
import useKeyPress from '@/hooks/use-key-press';
import AutoPlay from '@/lib/keen-slider-autoplay';
import WheelControls from '@/lib/keen-slider-wheel-controls';

interface ILatestNewsCarousel {
  className?: string;
  options?: KeenSliderOptions;
  items: {
    title: string;
    thumbnail: string;
    href: string;
    className?: string;
    category?: {
      name: string;
      id: string;
    };
  }[];
}

const LatestNewsCarousel = ({
  items,
  options,
  className,
}: ILatestNewsCarousel) => {
  const defaultOptions: KeenSliderOptions = options || {
    loop: items.length >= 5,
    slides: {
      perView: 1.5,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 640px)': {
        slides: {
          perView: 2.5,
          spacing: 24,
        },
      },
      '(min-width: 768px)': {
        slides: {
          perView: 3.5,
          spacing: 32,
        },
      },
      '(min-width: 1280px)': {
        slides: {
          perView: 5,
          spacing: 32,
        },
      },
    },
  };

  const [sliderRef, instanceRef] = useKeenSlider(defaultOptions, [
    WheelControls,
    AutoPlay,
  ]);

  // Fixes SSG issue where styles not get applied when navigating between pages
  React.useEffect(() => {
    instanceRef.current?.update({
      ...defaultOptions,
    });
  }, [instanceRef, defaultOptions]);

  useKeyPress('ArrowRight', () => {
    instanceRef.current?.next();
  });

  useKeyPress('ArrowLeft', () => {
    instanceRef.current?.prev();
  });

  if (!items) return null;

  return (
    <div className={clsx('relative w-full', className)}>
      <div ref={sliderRef} className="keen-slider">
        {items.map((item) => (
          <div key={`${item.title}`} className="keen-slider__slide">
            <PostCardAlt {...item} category={item.category?.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestNewsCarousel;
