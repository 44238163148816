import { gql } from 'graphql-request';

export const htmlFragment = gql`
  fragment htmlFragment on Html {
    id
    html
  }
`;

export interface Html {
  __typename: 'Html';
  id: string;
  html: string;
}
