import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery as useQueryBase } from '@tanstack/react-query';
import config from 'config';

import makeSiteGraphqlRequest from '@/utils/graphql-request';

export async function makeQuery<T>(
  query: string,
  variables: any,
  formatFn?: (data: any) => T
) {
  try {
    return await makeSiteGraphqlRequest(config, query, variables, formatFn);
  } catch (e) {
    console.error(e);

    const error = e as any;
    // handle graphql errors
    if (error.response?.errors) {
      throw new Error(error.response.errors[0].message);
    } else {
      throw e;
    }
  }
}

export default function useQuery<T>(
  query: string,
  variables?: any,
  formatFn?: (data: any) => T,
  options?: UseQueryOptions<T>
) {
  async function fetchData() {
    return makeQuery(query, variables, formatFn);
  }

  const { ...rest } = options || {};
  const enabled = options?.hasOwnProperty('enabled') ? options.enabled : true;

  const queryOptions: UseQueryOptions<T> = { enabled, ...rest };

  const { data, isLoading, error, refetch, isFetching } = useQueryBase(
    ['query', query, variables],
    fetchData,
    // @ts-ignore FIXME: this lint error
    {
      ...queryOptions,
    }
  );

  return {
    data,
    isLoading,
    isFetching,
    error: error as Error,
    fetchData: refetch,
  };
}
